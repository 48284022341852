import type { IPageContext } from "../cb/interfaces/context"
import { SpaMetaUpdate } from "../cb/components/head/spaMetaUpdate"
import { currentSiteSettings } from "../cb/siteSettings"
import { canVideoAutoplayInline } from "../common/canAutoPlay"
import { roomLoaded } from "../common/context"
import { getScrollbarWidth } from "../common/DOMutils"
import { bindLangsFormsCurrentUrl } from "../common/langUtil"
import { scheduleLogPresence } from "../common/logPresence"
import { addPageAction } from "../common/newrelic"
import { trackAllClicks } from "../common/pageActionSampling"
import { scheduleSentimentSurvey, SentimentSurveyType } from "../common/sentimentSurvey"
import { TheaterModeRoot } from "../common/theatermodelib/theaterModeRoot"
import { i18n } from "../common/translation"
import { videoModeHandler } from "../common/videoModeHandler"
import { BaseEntryPoint, exportEntryPoint } from "./base"


exportEntryPoint(class Page extends BaseEntryPoint {
    protected context: IPageContext

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected parseContext(contextObj: Record<string, any>): void {
        super.parseContext(contextObj)
        this.context.refreshFrequency = contextObj["refreshFrequency"]
        this.context.pageLocation = "chatroom"
    }

    protected init(): void {
        super.init()
        super.initRoom()

        this.setupTheatermode()
        videoModeHandler.fireAfflThtrMdEvents()
        trackAllClicks("UIClickChatroom")
    }

    protected setupTheatermode(): void {
        document.body.style.minWidth = `${500 - getScrollbarWidth()}px`
        const base = document.getElementById("base") as HTMLElement
        // make sure custom bio contents does not create horizontal scrolling
        // todo move these to the css so they would load earlier once the waffle switch is removed
        base.style.overflowX = "hidden"
        base.style.position = "relative"

        if (Boolean(this.context.showNpsSentimentSurveyGroup)) {
            scheduleSentimentSurvey(false, SentimentSurveyType.NPS_GROUP)
        }
        const roomName = window.location.pathname.match(/\/([^\/]+)\/?$/)?.[1] ?? ""
        if (roomName === "") {
            error("roomName is undefined")
        }
        document.title = i18n.documentTitle(roomName, currentSiteSettings.siteName)

        canVideoAutoplayInline().then((inlineAutoplaySupported) => {
            new TheaterModeRoot(roomName, inlineAutoplaySupported, this.context)
        }).catch((err) => {
            // this should never happen
            error("Video autoplay inline error", err)
            new TheaterModeRoot(roomName, false, this.context)
        })

        roomLoaded.listen((context) => {
            gtag("event", "page_view", { "page_title": context.dossier.room })
            scheduleLogPresence(context.chatConnection, context.dossier.exploringHashTag)
            addPageAction("RoomLoaded", { "location": "desktop" })
            bindLangsFormsCurrentUrl()
        })

        new SpaMetaUpdate()
    }
})
